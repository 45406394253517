<!-- 付款---收银台 -->
<template>
  <div class="payment">
    <el-row class='head'>
      <el-col :span="12">
        <logotitle title='收银台'></logotitle>
      </el-col>
      <el-col :span="12">
      </el-col>
    </el-row>
    <paymentdata :productData='productData' class='paymentdata'></paymentdata>
    <paymethod  :productData='productData' class='paymethod'></paymethod>
  </div>
</template>
<script>
import logotitle from '@/components/logotitle/logotitle.vue';//图片标题组件
import paymentdata from '@/components/paymentdata/paymentdata.vue';//付款--产品订单数据
import paymethod from '@/components/paymethod/paymethod.vue';//支付方式
export default {
  name: 'payment',
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
        name: "description",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition
      },
      {
        name: "Keywords",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey
      }
        ]
    }
  },
  data(){
    return {
      htmltitle:'收银台-微微定',
      productData:[],
    }
  },
  components: {
    logotitle,
    paymentdata,
    paymethod
  },
  props: {
    msg: String
  },
  methods:{
    localReset(){
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'Shop/payOrderPageData?orderName='+this._spf(this.$route.query.ordername),this.keys())
        .then(res => {
            if(res.data.Success){
              if(res.data.Data){
                var _obj = {};
                _obj.data = res.data.Data.orderName
                _obj.data5 = res.data.Data.PayAmount
                _obj.data4 = res.data.Data.ContactName
                _obj.data6 = res.data.Data.ContactPhone
                _obj.data7 = res.data.Data.DepositBalance
                _obj.data8 = res.data.Data.DepositAmount
                _obj.data1 = res.data.Data.ShippingAddress
                _obj.data3 = res.data.Data.ShippingDateFormat
                _obj.data2 = []
                for(let i in res.data.Data.jobList){
                  var _sonobj = {};
                  _sonobj.name = res.data.Data.jobList[i].jobName+'*'+res.data.Data.jobList[i].Copies
                  _obj.data2.push(_sonobj)
                }
                this.$set(this,'productData',_obj)
              }
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      },exp:true,catch:()=>{
          // 用户不存在
          this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
        }})
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b,_pomise.c])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        this.localReset()
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  }
}
</script>
<style  lang="scss" scoped>
.payment {
  width: 100%;background: #fff;
  .head {
    width: 1200px;margin:0 auto;padding-top:20px;padding-bottom:20px;
    .buystep {
      float:right;margin-top:10px;
    }
  }
  .paymentdata {
    width: 1200px;margin:0 auto;margin-bottom:30px;
  }
  .paymethod {
    width: 1200px;margin:0 auto;padding-bottom:70px;
  }
}
</style>
