<!-- 支付方式 -->
<template>
  <div class="paymethod" >
    <h6>请选择支付方式</h6>
    <div class="box">
      <div class="local">
        <button @click='choicePay(0)'><svg v-show='paymethodchoice==0'><use xlink:href="#iconwancheng"></use></svg></button>
        <p class="ck" @click='choicePay(0)'>预存支付</p>
        <p class="co">（预存：<span>{{productData.data7 }}元</span>）</p>
        <button @click='choicePay(3)'><svg v-show='paymethodchoice==3'><use xlink:href="#iconwancheng"></use></svg></button>
        <p class="ck" @click='choicePay(3)'>余额支付</p>
        <p  class="co">（余额：<span>{{productData.data8 }}元</span>）</p>
      </div>
      <div class="other">
        <button @click='choicePay(1)' :class='{active:paymethodchoice==1}'><svg ><use xlink:href="#iconzhifubaozhifu"></use></svg>使用支付宝支付
        <span><svg ><use xlink:href="#iconwancheng"></use></svg></span>
        </button>
        <button @click='choicePay(2)' :class='{active:paymethodchoice==2}'><svg ><use xlink:href="#iconweixinzhifu"></use></svg>使用微信支付
        <span><svg ><use xlink:href="#iconwancheng"></use></svg></span>
        </button>
      </div>
    </div>
    <div class="pay">
      <p><span>您选择了通过{{paymethods[paymethodchoice]}}支付</span></p>
      <p>支付金额：<span><i>&yen;</i>{{productData.data5 }}</span></p>
      <!-- <button @click='payclickDelay' >立即支付</button> -->
      <button @click='payclick' >立即支付</button>
    </div>
    <el-dialog
      :show-close='false'
      :visible.sync="dialogVisible"
      top='35vh'
      width="300px"
      custom-class='exppasbox'
      >
      <div  slot="title" class="exptit">
        <p>输入密码</p>
        <button  @click="dialogVisible = false"><svg><use xlink:href="#iconshanchu"></use></svg></button>
      </div>
      <div class="exppas">
        <div data-v-22bcf923="" class="divbox" >
          <div data-v-22bcf923="" class="div" @click='inputbox'>
            <input data-v-22bcf923="" maxlength="1" type="password" autocomplete="new-password" ref='ipt' @input='clickHandler($event.target.value,0)' @keyup.delete='backdel(0)'>
            <input data-v-22bcf923="" maxlength="1" type="password" autocomplete="new-password" ref='ipt1' @input='clickHandler($event.target.value,1)'@keyup.delete='backdel(1)'>
            <input data-v-22bcf923="" maxlength="1" type="password" autocomplete="new-password" ref='ipt2' @input='clickHandler($event.target.value,2)'@keyup.delete='backdel(2)'>
            <input data-v-22bcf923="" maxlength="1" type="password" autocomplete="new-password" ref='ipt3' @input='clickHandler($event.target.value,3)'@keyup.delete='backdel(3)'>
            <input data-v-22bcf923="" maxlength="1" type="password" autocomplete="new-password" ref='ipt4' @input='clickHandler($event.target.value,4)'@keyup.delete='backdel(4)'>
            <input data-v-22bcf923="" maxlength="1" type="password" autocomplete="new-password" ref='ipt5' @input='clickHandler($event.target.value,5)'@keyup.delete='backdel(5)'>
          </div>
          <p data-v-22bcf923="" class="links" @click='forgetpassword'>忘记支付密码？</p>
        </div>
        <div class="menu">
          <button @click='dialogVisible = false'>取消</button>
          <button @click='paynow' :disabled="disabled">立即支付</button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {mixin} from '../../assets/js/common.js' //公用js
export default {
  name: 'paymethod',
  mixins: [mixin],//混入
  data(){
    return {
      userinfo:{
        data:0
      },//个人信息
      paymethods:['预存','支付宝','微信','余额'],//支付方式
      paymethodchoice:0,//支付方式
      password:'',//支付密码
      hs:this.$createElement,
      dialogVisible:false,
      disabled:false
    }
  },
  props: {
    productData: null
  },
  methods:{
    paynow(){
      this.disabled = true
      var _this = this;
      // 立即支付
      if(_this.paymethodchoice==0){
      //预存支付
        _this._axios({then:()=>{
          _this.axios.post(process.env.VUE_APP_URL+'Shop/payOrder?PayPassWord='+_this.password+'&orderName='+_this.productData.data+'&payType='+(parseInt(_this.paymethodchoice)+1),_this.keys({token:this._token({method:'get'})}))
          .then(res => {
              if(res.data.Success){
                this.disabled = false
                if(res.data.Data.state==-1){
                  _this.$Message({message:res.data.Data.message,type:'error',duration:1500})
                  _this.inputbox()
                }else{
                  this.dialogVisible = false
                  setTimeout(()=>{
                    _this.$router.push({path:'/payresult',query:{ordername:_this.productData.data+'.html'}})
                    // _this.$router.push({path:'payresult',query:{state:1,data:JSON.stringify(res.data.Data)}})
                  },100)
                }
              }else{
                this.disabled = false
                 this.dialogVisible = false
                this.$MessageBox.alert(res.data.Error,{type:'error'})
              }
            }, res => {
              // _this.$MessageBox.alert('系统异常',{type:'error'});
            })
        },exp:true,catch:()=>{
          // 用户不存在
          _this.$router.push({path:'/accountlogin',query:{redirect:_this.$route.fullPath+'.html'}})
        }})
      }
      if(_this.paymethodchoice==3){
      //余额支付
        _this._axios({then:()=>{
          _this.axios.post(process.env.VUE_APP_URL+'Shop/payOrder?PayPassWord='+_this.password+'&orderName='+_this.productData.data+'&payType='+(parseInt(_this.paymethodchoice)+1),_this.keys({token:this._token({method:'get'})}))
          .then(res => {
              if(res.data.Success){
                this.disabled = false
                if(res.data.Data.state==-1){
                  _this.$Message({message:res.data.Data.message,type:'error',duration:1500})
                  _this.inputbox()
                }else{
                  this.dialogVisible = false
                  setTimeout(()=>{
                    _this.$router.push({path:'/payresult',query:{ordername:_this.productData.data+'.html'}})
                    // _this.$router.push({path:'payresult',query:{state:1,data:JSON.stringify(res.data.Data)}})
                  },100)
                }
              }else{
                this.disabled = false
                this.dialogVisible = false
                this.$MessageBox.alert(res.data.Error,{type:'error'})
              }
            }, res => {
              // _this.$MessageBox.alert('系统异常',{type:'error'});
            })
        },exp:true,catch:()=>{
          // 用户不存在
          _this.$router.push({path:'/accountlogin',query:{redirect:_this.$route.fullPath+'.html'}})
        }})
      }
    },
    choicePay(v){
      // 选择支付方式
      this.paymethodchoice = v
    },
    payclickDelay(){
      // 支付延迟
      setTimeout(()=>{
        this.payclick()
      },50)
    },
    payclick(){
      // 支付
      var _this =this;
      // const h = this.$createElement;
      // this.password = '';//重置密码
      // if(this.$refs.ipt){
      //   this.$refs.ipt.value=''
      //   this.$refs.ipt1.value=''
      //   this.$refs.ipt2.value=''
      //   this.$refs.ipt3.value=''
      //   this.$refs.ipt4.value=''
      //   this.$refs.ipt5.value=''
      // }
      if(this.paymethodchoice==0||this.paymethodchoice==3){
        // this.productData.data7
        // this.productData.data5
        if(!this.$store.state.user.IsSetPaypassWord){
          this.$MessageBox.confirm('您还没有设置支付密码', {
            confirmButtonText: '去设置',
            cancelButtonText: '取消',
            type: 'error',
            customClass:'joinCar'
          }).then(() => {
            this._router({then:()=>{
              // this.$router.push({path:'/safecenter'})
              this.$router.push('/setsteps.html')
            }})
            // console.log('去设置')
          }).catch(() => {
            // console.log('取消')
          });
          return;
        }
        if(this.productData.data7<this.productData.data5&&this.paymethodchoice==0){
          this.$MessageBox.confirm('预存不足，是否立即充值', {
            confirmButtonText: '立即充值',
            cancelButtonText: '取消',
            type: 'error',
            customClass:'joinpassword1'
          }).then(() => {
            this._router({then:()=>{
              this.$router.push({path: "/userrecharge.html"})
            },exp:true,catch:()=>{
              this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
              // this._open({path: "/accountlogin",query: {redirect:'/'}})
            }})
            // console.log('立即充值')
          }).catch(() => {
            console.log('取消')
          });
          return;
        }
        if(this.productData.data8<this.productData.data5&&this.paymethodchoice==3){
          this.$MessageBox.alert('余额不足',{type:'error'});
          return;
        }
        this.dialogVisible = true;
        // this.$MessageBox.confirm('','输入密码',{
        //   confirmButtonText: '立即支付',
        //   cancelButtonText: '取消',
        //   message:this.hs('div',{attrs:{class:'divbox',}},[
        //     this.hs('div',{attrs:{class:'div',},on: {click:this.inputbox}},[
        //       this.hs('input', {attrs:{maxlength:'1',type:'password',autocomplete:'new-password'},on: {input: function (event) { _this.clickHandler(event.target.value,0);},keyup:function(event){if(event.keyCode==8){_this.backdel(0);}}},ref:'ipt'}),
        //       this.hs('input', {attrs:{maxlength:'1',type:'password',autocomplete:'new-password'},on: {input: function (event) { _this.clickHandler(event.target.value,1);},keyup:function(event){if(event.keyCode==8){_this.backdel(1);}}},ref:'ipt1'}),
        //       this.hs('input', {attrs:{maxlength:'1',type:'password',autocomplete:'new-password'},on: {input: function (event) { _this.clickHandler(event.target.value,2);},keyup:function(event){if(event.keyCode==8){_this.backdel(2);}}},ref:'ipt2'}),
        //       this.hs('input', {attrs:{maxlength:'1',type:'password',autocomplete:'new-password'},on: {input: function (event) { _this.clickHandler(event.target.value,3);},keyup:function(event){if(event.keyCode==8){_this.backdel(3);}}},ref:'ipt3'}),
        //       this.hs('input', {attrs:{maxlength:'1',type:'password',autocomplete:'new-password'},on: {input: function (event) { _this.clickHandler(event.target.value,4);},keyup:function(event){if(event.keyCode==8){_this.backdel(4);}}},ref:'ipt4'}),
        //       this.hs('input', {attrs:{maxlength:'1',type:'password',autocomplete:'new-password'},on: {input: function (event) { _this.clickHandler(event.target.value,5);},keyup:function(event){if(event.keyCode==8){_this.backdel(5);}}},ref:'ipt5'}),
        //     ]),
        //     this.hs('p', {attrs:{class:'links'},domProps:{innerHTML:'忘记支付密码？'},on: {click:this.forgetpassword}}),
        //   ]),
        //   customClass:'joinpassword',
        //   beforeClose:function(action, instance, done){
        //     if(action=='cancel'){
        //       done()
        //     }
        //     if(action=='confirm'){
        //     }
        //   }
        // }).catch(()=>{})
        // setTimeout(()=>{
        //   if(_this.$refs.ipt){
        //     _this.$refs.ipt.focus()
        //   }
        // },50)
      }
      if(this.paymethodchoice==1){
        this._router({then:()=>{
          this.$router.push({path:'/paymentalipay',query:{orderName:this.productData.data+'.html'}})
        },exp:true,catch:()=>{
          // 用户不存在
          this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
        }})
      }
      if(this.paymethodchoice==2){
        this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'Shop/payOrder?PayPassWord=&orderName='+this.productData.data+'&payType=3',this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              this._router({then:()=>{
                this.$router.push({path:'/paymentwechat',query:{orderName:this.productData.data+'.html'}})
              }})
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      },exp:true,catch:()=>{
          // 用户不存在
          this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
        }})
      }
    },
    clickHandler(v,index){
        // 弹窗子组件监听事件输入密码
        var reg = new RegExp("^[0-9]*$");
        switch(index) {
          case 0:
            if(!reg.test(v)){
              this.$refs.ipt.value=''
              return;
            }
            this.$refs.ipt1.focus()
            this.password += v
            break;
          case 1:
            if(!reg.test(v)){
              this.$refs.ipt1.value=''
              return;
            }
            this.$refs.ipt2.focus()
            this.password += v
            break;
          case 2:
            if(!reg.test(v)){
              this.$refs.ipt2.value=''
              return;
            }
            this.$refs.ipt3.focus()
            this.password += v
            break;
          case 3:
            if(!reg.test(v)){
              this.$refs.ipt3.value=''
              return;
            }
            this.$refs.ipt4.focus()
            this.password += v
            break;
          case 4:
            if(!reg.test(v)){
              this.$refs.ipt4.value=''
              return;
            }
            this.$refs.ipt5.focus()
            this.password += v
            break;
          case 5:
            if(!reg.test(v)){
              this.$refs.ipt5.value=''
              return;
            }
            this.$refs.ipt5.blur();
            // var _v = v;
            // if(v.length>1){
            //   this.$refs.ipt5.value=_v
            // }
            this.password += v
            // console.log(this.password)
            break;
        }
    },
    inputbox(){
      // 默认选中子组件第一个input---重置输入
      this.password=''
      this.$refs.ipt.value=''
      this.$refs.ipt1.value=''
      this.$refs.ipt2.value=''
      this.$refs.ipt3.value=''
      this.$refs.ipt4.value=''
      this.$refs.ipt5.value=''
      this.$refs.ipt.focus()
    },
    forgetpassword(){
      // 忘记密码跳转
      this.dialogVisible = false
      this._router({then:()=>{
        this.$router.push({path: "/idverify?types=1.html"})
        // this._open({path: "/changepas"})
      },exp:true,catch:()=>{
        this.$router.push({path:'/accountlogin',query:{redirect:"/idverify?types=1.html"}})
        // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    },
    backdel(index){
      // 回退删除--重置输入的密码
      var _password;
      switch(index) {
        case 0:
          this.$refs.ipt.focus()
          this.$refs.ipt.value=''
          this.password = ''
          break;
        case 1:
          this.$refs.ipt.focus()
          this.$refs.ipt.value=''
          _password = this.password.split('');
          _password.splice(_password.length-1,1)
          this.password = _password.join('')
          break;
        case 2:
          this.$refs.ipt1.focus()
          this.$refs.ipt1.value=''
          _password = this.password.split('');
          _password.splice(_password.length-1,1)
          this.password = _password.join('')
          break;
        case 3:
          this.$refs.ipt2.focus()
          this.$refs.ipt2.value=''
          _password = this.password.split('');
          _password.splice(_password.length-1,1)
          this.password = _password.join('')
          break;
        case 4:
          this.$refs.ipt3.focus()
          this.$refs.ipt3.value=''
          _password = this.password.split('');
          _password.splice(_password.length-1,1)
          this.password = _password.join('')
          break;
        case 5:
          this.$refs.ipt4.focus()
          this.$refs.ipt4.value=''
          _password = this.password.split('');
          _password.splice(_password.length-1,1)
          this.password = _password.join('')
          break;
      }
    }
  },
  mounted(){
  },
  watch:{
    dialogVisible(n,o){
      if(n){
        setTimeout(()=>{
          this.inputbox()
        },50)
      }else{
        setTimeout(()=>{
          this.inputbox()
        },50)
      }
    }
  }
}
</script>
<style lang="scss">
.joinCar {
  .el-message-box__btns {
    button {
      &:hover {
        color:#333;background: rgba(0,0,0,0.08);border:1px solid #ccc;
      }
    }
    .el-button--primary {
      background: #f54d70;border:1px solid #f54d70;
      &:hover {
        color:#fff;background: #f54d70;opacity: 0.8;border:1px solid #f54d70;
      }
    }
  }
}

</style>
<style lang="scss">
  .joinpassword {
    width: 315px;
    .el-message-box__content {
      padding:0px;
    }
    .el-message-box__header {
      background: #f3f3f3;padding:0px;height: 40px;display: flex;align-items: center;padding-left:10px;font-size: 16px;
      .el-message-box__title {
        span {
          color:#6f6f6f;
        }
      }
      .el-message-box__headerbtn {
        margin-top:-3px;
      }
    }
    .el-message-box__message {
      .div {
        position: relative;display: flex;align-items: center;justify-content: center;width: 234px;height: 38px;margin:0 auto;border:1px solid #e8e8e8;margin-top:20px;cursor: pointer;
        input {
          border-right:1px solid #ededed;width: 38px;height: 24px;text-align: center;
          &:last-child {
            border-right:0px;
          }
        }
        &:after {
          content: '';display: block;width: 100%;height: 100%;background: rgba(0,0,0,0);top:0px;left:0px;position: absolute;
        }
      }
      .links {
        margin-top:10px;margin-bottom:5px;cursor: pointer;color:#7296e5;font-size: 14px;display: inline-block;margin-left:40px;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .el-message-box__btns {
      display: flex;justify-content: center;margin-bottom:5px;
      .el-button {
        width: 94px;height: 28px;line-height: 28px;padding:0px;border:1px solid #d1d1d1;background: #fff;color:#333;
        &:hover {
          opacity: 0.8;
        }
      }
      .el-button--primary {
        color:#fff;background: #fa4c6f;border-color:#fa4c6f;
      }
    }
  }
  .joinpassword1 {
    .el-message-box__btns {
      .el-button {
        width: 94px;height: 28px;line-height: 28px;padding:0px;border:1px solid #d1d1d1;background: #fff;color:#333;
        &:hover {
          opacity: 0.8;
        }
      }
      .el-button--primary {
        color:#fff;background: #fa4c6f;border-color:#fa4c6f;
      }
    }
  }
</style>
<style  lang="scss" scoped>
.paymethod {
  h6 {
      font-size: 20px;letter-spacing:3px;position: relative;padding-left:10px;margin-bottom:10px;
      button {
        float:right;background: none;color:#3f69bb;margin-right: 20px;margin-top:5px;
      }
      &:after {
        content: '';display: block;width: 2px;height: 20px;background: #d65d6e;position: absolute;top:4px;left:0px;
      }
  }
  .box {
    background: #f9fafe;border-radius: 5px;
    .local {
      padding:0px 10px;border-bottom:1px solid #ebecf0;display: flex;justify-content: flex-start;height: 65px;align-items: center;
      button {
        background: none;border:1px solid #af7081;width: 14px;height: 14px;margin-right: 10px;
        svg {
          fill: #af7081;width: 11px;height: 11px;vertical-align: 1px;
        }
      }
      p {
        color:#101010;
      }
      .ck{
        cursor: pointer;
      }
      .co {
        color:#757575;
        span {
          color:#e83838;
        }
      }
    }
    .other {
      display: flex;height: 120px;align-items: center;padding-left:10px;
      button {
        margin-right:25px;width: 200px;height: 64px;background: none;border:1px solid #cfd0d4;border-radius: 10px;font-size: 14px;font-weight: bold;display: flex;align-items: center;justify-content: center;position: relative;overflow: hidden;
        svg {
          width: 40px;height: 40px;margin-right: 10px;
        }
        span {
          display: block;width: 50px;height: 50px;background: #fb4b6f;position: absolute;bottom:-24px;right:-24px;transform: rotate(45deg);visibility: hidden;
          svg {
            transform: rotate(-45deg);width: 18px;height: 18px;float:left;margin-top:15px;fill: #fff;margin-left:2px;
          }
        }
        &:after {
          display:block;content: "";width: 197px;height: 61px;border:2px solid #fb4b6f;position: absolute;top:-1px;left:-1px;border-radius: 10px;visibility: hidden;
        }
      }
      .active {
        border:0px;
        span {
          visibility: visible;
        }
        &:after{
          visibility: visible;
        }
      }
    }
  }
  .pay {
    text-align: right;margin-top:20px;
    p {
      margin-bottom:10px;color:#4d4d4f;
      &:nth-child(1) {
        span {
          background: #f9fafe;padding:2px 5px;
        }
      }
      &:nth-child(2) {
        span {
          width: 125px;display: inline-block;font-size: 20px;font-weight: bold;color:#d73648;
          i {
            font-style: normal;margin-right: 2px;
          }
        }
      }
    }
    button {
      background: #f54e56;color:#fff;width: 140px;height: 36px;font-size: 22px;border-radius: 5px;margin-top:20px;line-height: 36px;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  /deep/.exppasbox {
    border-radius: 10px;
    .el-dialog__header,.el-dialog__body {
      padding:0px;
    }
    .exptit {
      height: 40px;width: 100%;background: #f3f3f3;border-top-left-radius: 10px;border-top-right-radius: 10px;position: relative;
      p {
        height: 40px;line-height: 40px;color:#878787;font-size: 14px;font-weight: bold;padding-left:15px;
      }
      button {
        position: absolute;top:0px;right:0px;background: none;top:12px;right:15px;
        svg {
          fill: #8e8e8e;width: 15px;height: 15px;
        }
      }
    }
    .exppas {
      padding-bottom:20px;
      .div {
        position: relative;display: flex;align-items: center;justify-content: center;width: 234px;height: 38px;margin:0 auto;border:1px solid #e8e8e8;margin-top:20px;cursor: pointer;
        input {
          border-right:1px solid #ededed;width: 38px;height: 24px;text-align: center;
          &:last-child {
            border-right:0px;
          }
        }
        &:after {
          content: '';display: block;width: 100%;height: 100%;background: rgba(0,0,0,0);top:0px;left:0px;position: absolute;
        }
      }
      .links {
        margin-top:10px;margin-bottom:5px;cursor: pointer;color:#7296e5;font-size: 14px;display: inline-block;margin-left:32px;
        &:hover {
          opacity: 0.8;
        }
      }
      .menu {
        display: flex;justify-content: center;width: 198px;margin:0 auto;height: 28px;margin-top:10px;
        button {
          width: 94px;height: 28px;background: #fa4c6f;border-radius: 3px;font-size: 12px;line-height: 30px;
          &:nth-child(1) {
            margin-right:10px;border:1px solid #d1d1d1;background: none;color:#333;
          }
          &:nth-child(2) {
            color:#fff;
          }
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}
</style>
