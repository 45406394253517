<!-- 付款---订单数据 -->
<template>
  <div class="paymentdata">
    <div class='box'>
      <div class="title">
        <div class="lt">
          <h6 class="h6">恭喜您,订单已提交成功！订单号：{{productData.data}}</h6>
          <p>请您在<span>24小时</span>内完成支付，否则订单将会被自动取消</p>
        </div>
        <div class="rt">
          <div class="lts">应付总额：</div>
          <div class="rts">
            <p>&yen;{{productData.data5 }}</p>
            <button  @click='switchInfo'>
              订单详情
              <svg v-show='!openInfo'><use xlink:href="#iconxiangxiaxialakuang"></use></svg>
              <svg v-show='openInfo'><use xlink:href="#iconxiangshangxialakuang"></use></svg>
            </button>
          </div>
        </div>
      </div>
      <div class="info"  v-show='openInfo'>
        <div class="group-info">
          <div class="lt">收货信息：</div>
          <div class="rt">{{productData.data4}} {{productData.data6 | formatPhoneHide(1)}}</div>
        </div>
        <div class="group-info">
          <div class="lt">收货地址：</div>
          <div class="rt">{{productData.data1}}</div>
        </div>
        <div class="group-info">
          <div class="lt">商品名称：</div>
          <div class="rt">
            <p v-for='i,n in productData.data2' :key='n'>{{i.name}}</p>
          </div>
        </div>
        <div class="group-info">
          <div class="lt">下单时间：</div>
          <div class="rt"><span class="time">{{productData.data3}}</span></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mixin} from '../../assets/js/common.js' //公用js
export default {
  name: 'paymentdata',
  mixins: [mixin],//混入
  components: {
  },
  data(){
    return {
      // 张敏，广东省深圳市宝安区塘尾地铁站标凡科技大厦6楼，13955454544
      openInfo:false,
      // productData:{
      //   // data:123213,
      //   // data1:'广东省深圳市宝安区塘尾地铁站标凡科技大厦6楼',
      //   // data2:[
      //   //   {name:'广告纸巾定做钱夹试纸巾荷包纸巾宣传餐厅纸巾印刷logo微微定纸巾定制'},
      //   //   {name:'广告纸巾定做钱夹试纸巾荷包纸巾宣传餐厅纸巾印刷logo微微定纸巾定制'}
      //   // ],
      //   // data3:'2019-10-18 18:38',
      //   // data4:'张敏',
      //   // data5:418,
      //   // data6:18752360925
      // },//订单数据
    }
  },
  props: {
    productData: null
  },
  methods:{
    switchInfo(){
      // 打开关闭详情
      this.openInfo = !this.openInfo
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // this._axios({then:()=>{
      //   this.axios.post(process.env.VUE_APP_URL+'Shop/payOrderPageData?orderName='+this.$route.query.ordername,this.keys())
      //   .then(res => {
      //       if(res.data.Success){
      //         var _obj = {};
      //         _obj.data = res.data.Data.orderName
      //         _obj.data5 = res.data.Data.PayAmount
      //         _obj.data4 = res.data.Data.ContactName
      //         _obj.data6 = res.data.Data.ContactPhone
      //         _obj.data1 = res.data.Data.ShippingAddress
      //         _obj.data3 = res.data.Data.ShippingDateFormat
      //         _obj.data2 = []
      //         for(let i in res.data.Data.jobNameList){
      //           var _sonobj = {};
      //           _sonobj.name = res.data.Data.jobNameList[i]
      //           _obj.data2.push(_sonobj)
      //         }
      //         this.$set(this,'productData',_obj)
      //       }else{
      //         this.$MessageBox.alert(res.data.Error,{type:'error'});
      //       }
      //     }, res => {
      //       this.$MessageBox.alert('系统异常',{type:'error'});
      //     })
      // },exp:true,catch:()=>{
      //     // 用户不存在
      //     this.$router.push({path:'accountlogin',query:{redirect:this.$route.fullPath}})
        // }})
    })
  }
}
</script>
<style  lang="scss" scoped>
.paymentdata {
  width: 100%;border:1px solid #dcdcdc;
  .box {
    padding:20px;
  }
  .title {
    display: flex;justify-content: space-between;border-bottom:1px solid #eeebeb;padding-bottom:10px;margin-bottom:15px;
    .lt {
      .h6 {
        font-size:18px;
      }
      p {
        color:#676767;font-size: 14px;background: #ffeeee;border: 1px solid #ffabac;display: inline-block;padding:2px 5px;margin-top:5px;border-radius: 5px;
        span {
          color:#e55a46;
        }
      }
    }
    .rt {
      display: flex;justify-content: flex-start;margin-top:5px;
      .rts {
        width: 120px;text-align: right;
        p {
          font-size: 18px;margin-top:-5px;font-weight: bold;color:#d03b51;
        }
        button {
          background: none;color:#295594;margin-top:5px;
          svg {
            fill: #295594;width: 15px;height: 15px;vertical-align: -2px;margin-left:5px;
          }
        }
      }
    }
  }
  .group-info {
    display: flex;justify-content: flex-start;margin-top:10px;
    .lt {
      width: 70px;text-align: right;color:#2f2f2f;font-size: 14px;
    }
    .rt {
      color:#2f2f2f;font-size: 14px;
      p {
        margin-bottom:10px;
        &:last-child {
          margin-bottom:0px;
        }
      }
      .time {
        color:#c2c2c2;
      }
    }
  }
}
</style>
